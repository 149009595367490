import React from 'react';
import { Link } from 'gatsby';

import Layout from '@rocketseat/gatsby-theme-docs/src/components/Layout';
import SEO from '@rocketseat/gatsby-theme-docs/src/components/SEO';

export default function Contact() {
  return (
    <Layout title="Contact">
      <SEO title="contact Anjan Dutta" />
      <p>Please follow me in LinkedIn</p>
      <p>
        Here is a <Link to="https://www.linkedin.com/in/anjan-dutta" target="_blank">link</Link> to my profile.
      </p>
    </Layout>
  );
}